<template>
  <div>

    <!--  列表 -->
    <div class="flex item" v-for="(item,index) in list" :key="index" @click="detail(item.id)">

      <!--  左侧信息 -->
      <div style="flex: 1;margin-right: 1rem;overflow:hidden">

        <!--  标题 -->
        <div class="ellipsis" style="font-size: 1rem;font-weight: bolder;color: white">{{ item.title }}</div>

        <!--  内容 -->
        <div class="ellipsis" style="margin: 0.5rem 0">{{ item.zhaiYao }}</div>

        <!--  作者 ｜ 日期 操作按钮 标签 -->
        <div class="flex" style="justify-content: space-between;">
          <div class="flex">
            <div class="margin" style="color: white" v-if="!isMyBlog">{{ item.userName }}</div>
            <div class="margin" style="color: white">{{ item.time }}</div>
            <div class="flex margin">
              <img style="display: block;width: 1rem; height:1rem;margin-right: 0.5rem" src="../assets/img/yescount.png"/>
              <div style="color: white">{{ item.likeCount }}</div>
            </div>
            <div class="flex margin">
              <img style="display: block;width: 1rem; height:1rem;margin-right: 0.5rem" src="../assets/img/collectCount.png"/>
              <div style="color: white">{{ item.collectCount }}</div>
            </div>
            <el-dropdown v-if="isMyBlog" @command="handleCommand">
              <span class="dropdown-link"><i style="color: white" class="el-icon-more"/></span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command='{type: "edit", id: item.id}'><i class="el-icon-edit"/>编辑</el-dropdown-item>
                <el-dropdown-item style="color: red" :command='{type: "del", id: item.id}'><i class="el-icon-delete"/>删除
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="flex" style="justify-content: flex-end">
            <div class="tag" v-for="(e,i) in item.tagId" :key="i">{{ e }}</div>
          </div>
        </div>

      </div>

      <!-- 右侧图片 -->
      <div><img class="pic" :src="item.pic"/></div>

    </div>

    <!-- 分页 -->
    <el-pagination
        style="margin-top: 1rem"
        @current-change="handleCurrentChange"
        background
        layout="prev, pager, next, total"
        :current-page="pageNum"
        :page-size="pageSize"
        :total="total"
    />

  </div>
</template>

<script>
import {delMyBlog} from "@/api";
import {mixin} from "../mixins/index";

export default {
  mixins: [mixin],
  props: {
    list: [],
    isMyBlog: null,
    pageNum: null,
    pageSize: null,
    total: null,
  },
  methods: {
    handleCurrentChange(currentPageNum) {
      this.$emit("getList", currentPageNum);
    },
    detail(id) {
      this.$router.push({path: '/Detail', query: {id: id}});
    },
    handleCommand(command) {
      if (command.type === "edit") {
        this.$router.push({path: '/Send', query: {id: command.id}});
      }
      if (command.type === "del") {
        this.$confirm('确定删除此文章吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          showCancelButton: false,
          customClass: 'custom-confirm-dialog'
        }).then(() => {
          delMyBlog(command.id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.$emit("getList", this.pageNum);
            } else {
              this.$message.error("删除失败");
            }
          });
        }).catch(() => {
        });
      }
    }

  }

}
</script>

<style scoped>
.item {
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 0.01rem solid #FFF5EE;
  font-size: 0.8rem;
  cursor: pointer;
}

.pic {
  display: block;
  width: 7rem;
  height: 5rem;
  border-radius: 0.3rem;
  object-fit: cover;
}

.dropdown-link {
  color: #515767;
  cursor: pointer;
}

.margin {
  margin-right: 1.5rem;
}
</style>
