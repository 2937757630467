import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        HOST: 'http://localhost:8686',
        title: '',
        content: ''
    },
    getters: {
        memoryTitle: state => state.title,
        memoryContent: state => state.content
    },
    mutations: {
        setMemory: (state, val) => {
            state.title = val.title;
            state.content = val.contentValue;
        },
        delMemory: (state) => {
            state.title = '';
            state.content = '';
        }
    }

});

export default store;