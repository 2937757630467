import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            component: resolve => require(['../pages/Login.vue'], resolve)
        },
        {
            path: '/Home',
            component: resolve => require(['../components/Home.vue'], resolve),
            children: [
                {
                    path: '/Index',
                    component: resolve => require(['../pages/Index.vue'], resolve)
                },
                {
                    path: '/Center',
                    component: resolve => require(['../pages/Center.vue'], resolve)
                },
                {
                    path: '/Send',
                    component: resolve => require(['../pages/Send.vue'], resolve)
                },
                {
                    path: '/Detail',
                    component: resolve => require(['../pages/Detail.vue'], resolve)
                },
                {
                    path: '/Search',
                    component: resolve => require(['../pages/Search.vue'], resolve)
                },
                {
                    path: '/MyCollect',
                    component: resolve => require(['../pages/MyCollect.vue'], resolve)
                }
            ]
        },

    ]
})