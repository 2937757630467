import {post} from "./http";

// 注册
export const register = (params) => post(`user-manage/register`, params);

// 登录
export const login = (params) => post(`user-manage/login`, params);

// 详情
export const detail = (params) => post(`user-manage/detail`, params);

// 编辑资料
export const edit = (params) => post(`user-manage/edit`, params);

// 重置密码
export const reset = (params) => post(`user-manage/reset`, params);

// 上传文件
export const upload = (params) => post(`upload/cos`, params);

// 公告列表
export const listNotice = () => post(`notice-manage/list`, {ifByPage: 0});

// 分类列表
export const listType = () => post(`type-manage/list`, {ifByPage: 0});

// 标签列表
export const listTag = () => post(`tag-manage/list`, {ifByPage: 0});

// 发表博客
export const saveBlog = (params) => post(`blog-manage/save`, params);

// 作品列表&搜索
export const listMyBlog = (params = {}, pageNum, pageSize) => post(`blog-manage/list`, {...params, pageNum, pageSize});

// 删除作品
export const delMyBlog = (id) => post(`blog-manage/delete`, {id});

// 博客详情
export const getBlog = (id) => post(`blog-manage/detail`, {id});

// 发表评论
export const comment = (params) => post(`comment-manage/save`, params);

// 评论列表
export const listComment = (blogId) => post(`comment-manage/list`, {blogId});

// 删除评论
export const delComment = (id) => post(`comment-manage/delete`, {id});

// 关注列表
export const listFollow = (params = {}, pageNum, pageSize) => post(`follow-manage/list`, {...params, pageNum, pageSize});

// 关注/取关
export const followChange = (params) => post(`follow-manage/change`, params);

// 保存收藏夹
export const saveFavorite = (params) => post(`favorite-manage/save`, params);

// 收藏夹列表
export const listFavorite = (params = {}, pageNum, pageSize) => post(`favorite-manage/list`, {...params, pageNum, pageSize});

// 删除收藏夹
export const delFavorite = (id) => post(`favorite-manage/delete`, {id});

// 收藏列表
export const listCollect = (params = {}, pageNum, pageSize) => post(`collect-manage/list`, {...params, pageNum, pageSize});

// 收藏/取消收藏
export const collectChange = (params) => post(`collect-manage/change`, params);

// 点赞列表
export const listLike = (userId) => post(`like-manage/list`, {userId});

// 点赞/取消点赞
export const likeChange = (params) => post(`like-manage/change`, params);

// 热榜
export const hot5 = () => post(`blog-manage/hot`);

// 推荐
export const recommend = (params = {}, pageNum, pageSize) => post(`blog-manage/recommend`, {...params, pageNum, pageSize});

// 卡片
export const card = (userId) => post(`user-manage/count`, {userId});

// 评论点踩列表
export const listCommentLike = (userId) => post(`like-comment-manage/list`, {userId});

// 对评论点赞/点踩
export const likeCommentChange = (params) => post(`like-comment-manage/change`, params);
