<template>
  <div>

    <!-- 新建&确定按钮 -->
    <div style="display: flex;justify-content: space-between;padding: 1rem">
      <el-button class="custom-button" size="small" type="primary" plain @click="add">新建收藏夹</el-button>
      <el-button class="custom-button" v-if="!isMyCollect" size="small" @click="collect">确定</el-button>
    </div>

    <!-- 列表 -->
    <el-table
        :row-style="{ height: '5rem' }"
        :cell-style="{ paddingLeft: '1rem', color: isMyCollect ? '#FFF5EE' : '' }"
        :show-header="false"
        ref="multipleTable"
        :data="list"
        @row-click="listCollect"
        @selection-change="handleSelectionChange">
      <el-table-column>
        <template slot-scope="scope">
          {{ scope.row.name }}&nbsp;&nbsp;<el-tag :style="{ borderColor: '#DDA0DD', color: '#DDA0DD', backgroundColor: '#FBEFFB' }" size="mini" v-if="scope.row.type === 1">默认</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="isMyCollect">
        <template slot-scope="scope">
          <el-dropdown @command="handleCommand">
            <span class="dropdown-link"><i style="color: #FFF5EE" class="el-icon-more"/></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command='{type: "edit", id: scope.row.id, name: scope.row.name}'><i class="el-icon-edit"/>编辑
              </el-dropdown-item>
              <el-dropdown-item v-if="scope.row.type!==1" style="color: red" :command='{type: "del", id: scope.row.id}'><i
                  class="el-icon-delete"/>删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column v-else type="selection" width="70" align="center"/>
    </el-table>

    <!-- 分页 -->
    <el-pagination style="margin-top: 1rem" @current-change="handleCurrentChange" background layout="prev, pager, next, total"
                   :current-page="pageNum" :page-size="pageSize" :total="total"/>

    <!-- 保存弹窗 -->
    <el-dialog v-if="isShow" :visible.sync="isShow" width="30rem" @close="close" center :close-on-click-modal="false"
               :title="isAdd ? '新建收藏夹' : '编辑收藏夹'" append-to-body>
      <el-input style="margin: 1rem 0" v-model="form.name" placeholder="输入收藏夹名称"></el-input>
      <div style="display: flex;justify-content: center">
        <el-button class="custom-button" type="primary" size="small" @click="save">保存</el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import {delFavorite, saveFavorite} from "@/api";

export default {
  props: {
    list: [],
    isMyCollect: null,
    pageNum: null,
    pageSize: null,
    total: null
  },
  data() {
    return {
      multipleSelection: [],
      isAdd: false,
      isShow: false,
      form: {
        id: '',
        name: '',
        userId: localStorage.getItem('userId')
      }
    }
  },
  methods: {
    handleCurrentChange(currentPageNum) {
      this.$emit("getList", currentPageNum);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCommand(command) {
      if (command.type === "edit") {
        this.isAdd = false;
        this.form.name = command.name;
        this.form.id = command.id;
        this.isShow = true;
      }
      if (command.type === "del") {
        this.$confirm('确定删除此收藏夹吗?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          showClose: false,
          showCancelButton: false,
          customClass: 'custom-confirm-dialog'
        }).then(() => {
          delFavorite(command.id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.$emit("getList", this.pageNum);
            } else {
              this.$message.error("删除失败");
            }
          });
        }).catch(() => {
        });
      }
    },
    add() {
      this.isAdd = true;
      this.isShow = true;
    },
    close() {
      this.form.name = '';
      this.isShow = false;
    },
    save() {
      if (this.form.name === '') {
        this.$message.error("收藏夹名称不能为空");
        return;
      }
      saveFavorite(this.form).then(res => {
        if (res.success) {
          this.isShow = false;
          this.form.name = '';
          this.form.id = '';
          this.$emit("getList", 1);
          this.$message.success("保存成功");
        }
      });
    },
    collect() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择收藏夹");
        return;
      }
      this.$emit('saveCollect', this.multipleSelection.map(e => e.id));
    },
    listCollect(row) {
      if (this.isMyCollect) {
        this.$router.push({path: '/MyCollect', query: {favoriteId: row.id, favoriteName: row.name}});
      }
    }
  }
}
</script>
