// 公共方法
import {detail} from "@/api";

export const mixin = {
    data() {
        return {
            // 上传图片接口url
            upload: `${this.$store.state.HOST}/upload/cos`
        }
    },
    methods: {
        // 根据相对地址获取绝对地址
        getUrl(url) {
            return `${this.$store.state.HOST}${url}`;
        },
        // 上传图片的校验
        beforePicUpload(file) {
            const isJPG = (file.type === 'image/jpeg') || (file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传图片只能是jpg或png格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过2MB!');
            }
            return isJPG && isLt2M;
        },
        // 获取用户信息
        getUserInfo() {
            const id = localStorage.getItem("userId");
            return new Promise((resolve) => {
                detail({id}).then(res => {
                    if (res.success) {
                        resolve(res.data);
                    } else {
                        this.$message.error('查询失败');
                    }
                });
            });
        }
    }
}
